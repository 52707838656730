.title {
  font-size: 16px;
  margin-bottom: 12px;
}

.scanTip {
  font-size: 12px;
  line-height: 16px;
  padding: 10px;
  width: 170px;
  height: 53px;
  background: #f7fafc;
  border-radius: 3px;
  box-sizing: border-box;
  color: #8b95a5;
  display: flex;
  margin: 10px auto;
  text-align: left;

  p {
    margin-left: 12px;
  }
}

.payResult {
  font-size: 16px;
  font-weight: bold;
  text-align: center;

  img {
    display: inline-block;
    margin-bottom: 3px;
  }
}
@screen-xs: 10px;@screen-sm: 10px;@primary-color: #155bd4;@link-color: #3773da;@success-color: #2da641;@error-color: #d40000;@body-background: #f0f2f5;@upload-picture-card-size: 80px;