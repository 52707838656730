.pageWrapper {
  height: 100vh;
}

.body {
  height: 100%;
  background-image: url(https://cas.fulu.com/resources/images/bk.png);
  background-color: #F7F8FA;
}

.input {
  height: 40px;
}

.contentBox {
  background: #fff;
  -webkit-box-shadow: 0 0 5px 0 #e6e9ed;
  box-shadow: 0 0 5px 0 #e6e9ed;
  margin: 20px auto;
}

.loginContentBox {
  width: 500px;
  height: 516px;
}

.registerContentBox {
  width: 500px;
}

.formHeader {
  height: 78px;
  line-height: 78px;
  text-align: center;
  font-size: 18px;
  color: #333333;
  border-bottom: 1px solid #F5F5F7;
}

.agreementContent {
  margin: 0 8px;
  border: 1px solid #eee;
  padding: 16px 16px;
  height: 282px;
  text-indent: 2em;
  font-size: 12px;
}

.sendSmsButton {
  height: 45px;
  width: 120px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
}

.formWrapper {
  padding: 20px 100px;
}

.footer {
  display: table-row;
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-size: 12px;
}
@screen-xs: 10px;@screen-sm: 10px;@primary-color: #155bd4;@link-color: #3773da;@success-color: #2da641;@error-color: #d40000;@body-background: #f0f2f5;@upload-picture-card-size: 80px;