.login-tabs .ant-tabs-nav .ant-tabs-tab {
  font-size: 18px;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.auth-form {
  .ant-form-item-label label {
    font-size: 15px;
    font-weight: bold;
  }

  .ant-input, .ant-input-password {
    background-color: #F5F5F7 !important;
    border-radius: 4px;
    border-color: transparent;
    font-size: 15px;
  }

  .ant-form .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-btn {
    border-radius: 4px;
  }
}

.agreement-modal {
  .ant-modal-header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .agreement-title {
    margin: 12px 0 4px 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
}