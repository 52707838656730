.item {
    min-width: 100px;
    height: 32px;
    padding: 4px 16px;
    border: 1px solid rgb(202, 202, 202);
    border-radius: 3px;
    cursor: pointer;
}

.active {
    color: #155bd4;
    border: 1px solid #155bd4;
}

.active::after {
    content: '';
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    bottom: 17px;
    left: calc(100% - 7px);
    background: url(https://img01.yzcdn.cn/fix-base64/c4771dbca51a97c96aa78d8d0fe9da1252b4503c06a74bdacdbab580ea7641ec.png) no-repeat;
    background-size: 24px auto;
}

.item__label {
    height: 20px;
    text-align: center;
    user-select:none;
}