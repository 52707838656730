//region 紧凑型表单项
.form-compact .ant-form-item {
  margin-bottom: 16px;
}

//endregion

//region 查询表单
.query-form-container {
  background-color: #f2f3f5;
  padding: 24px 0 16px 40px;
}

.query-form__inputs_container {
  display: flex;
  flex-wrap: wrap;
}

.query-form__inputs_container .ant-form-item {
  margin-bottom: 18px;
}

.query-form__inputs_container > *:not(:last-child) {
  margin-right: 28px;
}

.query-form__operations {
  padding-left: 69px;
}

//endregion

//region others

.ant-btn.round {
  border-radius: 20px;
}

.ant-btn.buy-card-btn {
  color: #fff;
  border-color: rgb(237, 64, 20);
  background: rgb(237, 64, 20);
}

.ant-btn.buy-card-btn:hover {
  color: #fff;
  border-color: rgba(237, 64, 20, 1);
  background: rgba(237, 64, 20, 0.5);
}

//endregion

//region 自定义滚动条

.scroll {
  overflow-y: scroll;
  display: inline-block;

  // Simple
  &--simple::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &--simple::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &--simple::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &--simple::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &--simple::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, .9);
  }
}

//endregion