//region tailwind与antd样式冲突修正

// 引入tailwind的基础样式后，antd的某些组件会受到影响，已发现的包括：
// 1. message组件的图标与文字没有水平对齐；
// 2. 按钮loading图标与文字没有水平对齐。
// 3. checkbox与文字没有水平对齐
// 以下样式用来修正问题。

img, svg {
  vertical-align: baseline;
}

.ant-checkbox-wrapper {
  vertical-align: middle;
}

//endregion

//region antd Upload组件

// 上传时，显示的状态文字“正在上传中”会换行，所以将其隐藏掉
div.ant-upload-list-item-thumbnail {
  display: none !important;
}

//endregion

//region antd Image组件

// 预览时图片无法居中
.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

//endregion

//region antd modal body内边距过大，调整小一点
.ant-modal-body {
  padding: 16px 24px!important;
}

//endregion

// 解决Cascader默认高度太低的问题
.ant-cascader-menu {
  height: unset!important;
  min-height: 180px!important;
  max-height: 300px!important;
}