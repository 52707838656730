.channelCard {
  display: inline-block;
  padding: 12px 12px 8px 12px;
  border: 1px solid rgb(202, 202, 202);
  border-radius: 5px;
  cursor: pointer;
  width: 190px;

  &.selected {
    border: 1px solid #155bd4;
  }

  img {
    height: 38px;
    margin: auto;
  }

  div {
    margin-top: 3px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.875em;
  }
}
@screen-xs: 10px;@screen-sm: 10px;@primary-color: #155bd4;@link-color: #3773da;@success-color: #2da641;@error-color: #d40000;@body-background: #f0f2f5;@upload-picture-card-size: 80px;