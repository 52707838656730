@import '~antd/dist/antd.less';

@header-height: 56px;
@header-text-color: @text-color;

@aside-width: 212px;
@aside-text-color: #666;

@content-width: 1400px;

.console-page-container {
  padding-top: @header-height;

  .header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 var(--cb-color-shadow, rgba(0, 0, 0, 0.16));

    .header-content {
      display: flex;
      align-items: center;
      height: @header-height;
      width: @content-width;
      margin: 0 auto;
      color: @header-text-color;

      a {
        color: @header-text-color;
      }

      >.logo {
        min-width: @aside-width;
        font-size: 16px;
      }
      >.search {
        width: 200px;
      }
      >.menu {
        justify-items: end;
      }
    }
  }

  .main {
    margin: 0 auto;
    width: @content-width;
    z-index: 100;

    .aside {
      position: fixed;
      top: calc(@header-height + 8px);
      bottom: 0;
      padding-bottom: 20px;
      background: #fff;
      width: @aside-width;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 101;

      .customer-profile {
        padding: 16px 16px;
        background-image: linear-gradient(to bottom, #e6f7ff, white);

        img {
          width: 48px;
          height: 48px;
          display: inline-block;
          vertical-align: middle;
        }

        .desc {
          margin-left: 8px;
          display: inline-block;
          color: @aside-text-color;
          font-size: 14px;
          vertical-align: middle;

          .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 115px;
          }

          .id {
            font-size: 0.875em;
          }

          svg {
            font-size: 13px;
            color: #155bd4;
          }
        }

        .balance-wrapper {
          margin-top: 8px;
          display: flex;
          justify-content: space-around;

          .ant-statistic {
            display: inline-block;

            .ant-statistic-title {
              font-size: 12px;
              margin-bottom: 0;
            }

            .ant-statistic-content {
              font-size: 14px;
            }
          }
        }
      }

      .side-nav {
        width: 212px;
        overflow-y: hidden;
        overflow-x: hidden;
        box-sizing: border-box;
        transition: color .3s ease;
        color: transparent;
        padding: 0 20px;
        font-size: 14px;
        user-select: none;
      }

      .nav-item-group {
        padding-top: 6px;

        .nav-item-group-title {
          position: relative;
          color: rgba(0, 0, 0, .8);
          cursor: pointer;
          height: 33px;
          line-height: 33px;
          margin-bottom: 2px;

          img {
            position: absolute;
            top: 9px;
          }

          span {
            font-size: 15px;
            line-height: 34px;
            margin-left: 22px;
          }

          .pdd-left-nav-arrow-collapsed, .pdd-left-nav-arrow-expanded {
            border-color: #b9bfbd;
            border-style: solid;
            border-width: 0 0 1px 1px;
            display: inline-block;
            width: 8px;
            height: 8px;
            right: -18px;
            top: 12px;
            position: absolute;
            transition: transform .2s ease-out 0s;
            transform: rotate(-45deg);
            margin-right: 20px
          }
        }

        ul {
          list-style: none;
          margin: 0;
          padding-bottom: 3px;
          padding-left: 0;
        }

        .nav-item {
          display: inline-block;
          line-height: 28px;

          a .nav-item-text {
            display: inline-block;
            line-height: 1;
            position: relative;
            font-size: 14px;
            color: @aside-text-color;
          }
        }

        .nav-item.active a .nav-item-text {
          color: @primary-color;
        }

        .nav-item a .nav-item-text:hover {
          color: @primary-color;
          /*font-weight: bold;*/
        }

        .nav-item:nth-of-type(odd) {
          width: 88px;
          margin-right: 8px;
        }

        .nav-item:nth-of-type(2n) {
          width: 76px;
        }
      }
    }

    .content {
      margin-top: 8px;
      margin-left: calc(@aside-width + 10px);
    }

    > .ant-card {
      > .ant-card-head {
        min-height: 36px;
        padding: 0 20px;
        border-bottom: 1px solid #e5e5e5;

        .ant-card-head-title {
          padding: 12px 0;
        }
      }

      > .ant-card-body {
        padding: 20px;
      }
    }
  }
}


